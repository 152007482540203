const INTER = [{ space_src: 'baidujs', space_type: 'inters', space_id: 'u6943087', space_js: '//zfkpybq.yqzktech.com/source/pkjs_g/source/cqm/common/l.js' }];
const BANNER = [{ space_src: 'baidujs', space_type: 'native', space_id: 'u6943088', space_js: '//zfkpybq.yqzktech.com/production/q-l-kt/production/hdrnn.js' }];
const NATIVE_1 = [{ space_src: 'baidujs', space_type: 'native', space_id: 'u6943089', space_js: '//zfkpybq.yqzktech.com/site/r/production/m/l/static/uieso/u.js' }];
const NATIVE_2 = [{ space_src: 'baidujs', space_type: 'native', space_id: 'u6943090', space_js: '//zfkpybq.yqzktech.com/source/je/static/dma/resource/wkm_k.js' }];
const NATIVE_3 = [{ space_src: 'baidujs', space_type: 'native', space_id: 'u6943091', space_js: '//zfkpybq.yqzktech.com/source/k/fen/static/b/common/xlno.js' }];


/** 广告位配置 */
export const SPACE_MAP = {
    Home_inter: INTER,
    Home_banner: BANNER,
    Main_native: NATIVE_1,
    Main_native2: NATIVE_2,
    Main_native3: NATIVE_3,
    Classroom_native: NATIVE_2,
    Wikipedia_cat_native: NATIVE_1,
    Wikipedia_dog_native: NATIVE_2,
    Wikipedia_other_native: NATIVE_3,
    ConstellationList_banner: BANNER,
    ConstellationList_native: NATIVE_1,
    ArticleDetail_inter: INTER,
    ArticleDetail_banner: BANNER,
    ArticleDetail_native: NATIVE_1,
    PetDetail_inter: INTER,
    PetDetail_banner: BANNER,
    PetDetail_native: NATIVE_1,
    Mood_dialog: NATIVE_3,
};
