/** 根据url获取参数 */
export const getParamsFromUrl = (url: string): AnyObject => {
    let paramStr = url.split('?')[1];
    if (!paramStr) return {};
    let kavs = paramStr.split('&');
    let result: AnyObject = {};
    kavs.forEach(item => {
        let kv = item.split('=');
        result[kv[0]] = kv[1].replace('#/', '');
    })
    return result;
}

/** 获取随机数量字符串 */
export const randomString = (len: number) => {
    len = len || 32;
    var t = "ABCDEFGHJKMNPQRSTWXYZabcdefhijkmnprstwxyz2345678",
        a = t.length,
        n = "";
    for (let i = 0; i < len; i++) {
        let index = Math.floor(Math.random() * a);
        let str = t.substr(index, 1);
        n += str;
    }
    return n;
}

/** 随机整数字符串 */
export const randomInt = function (e?: number) {
    e = e || 4;
    var t = "123456789",
        a = t.length,
        n = "";
    for (let i = 0; i < e; i++) {
        let index = Math.floor(Math.random() * a);
        let str = t.substr(index, 1);
        n += str;
    }
    return n
}
