import { ref } from 'vue';

/** 首页插屏是否展示过 */
const HOME_INTER_SHOWN = ref<boolean>(false);

/** 首页banner是否展示（是否需要占位） */
const HOME_BANNER_SHOW = ref<boolean>(true);

export function useAdControl() {
    return { HOME_INTER_SHOWN, HOME_BANNER_SHOW };
}
